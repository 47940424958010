const DASHBOARD = "dashboard";
const MY_DASHBOARD = "dashboard-my-dashboard";
const VIEW_MY_PROFILE = "dashboard-view-my-profile";
const EDIT_MY_PROFILE = "dashboard-edit-my-profile";
const CHANGE_MY_PASSWORD = "dashboard-change-my-password";
const FAMILIES = "families";
const VIEW_FAMILY_PROFILE = "families-view-family-profile";
const EDIT_FAMILY_PROFILE = "families-edit-family-profile";
const CHANGE_FAMILY_PASSWORD = "families-change-family-password";
const ADD_NEW_CLIENT = "families-add-new-client";
const CLIENTS_LIST = "families-clients-list";
const EMAIL_ARCHIVE = "families-email-archive";
// const VIEW_EMAIL_ARCHIVE = "families-view-email-archive";
const PAGE_SETTINGS = "families-page-settings";
const BILLING_INFORMATION = "families-billing-information";
const COMPLETED_LIMBO_STEPS = "families-completed-limbo-steps";
const LOGIN_AS_FAMILY = "families-login-as-family";
const MANAGE_FAMILIES = "manage-families";
const MY_FAMILIES = "my-families";
const CREATE_FAMILY = "create-family";
const LIMBO_FAMILIES = "limbo-families";
const EXPORT_FAMILIES = "export-families";
const CLIENTS = "clients";
const VIEW_CLIENT_PROFILE = "clients-view-client-profile";
const EDIT_CLIENT_PROFILE = "clients-edit-client-profile";
const EVALUATION_VIDEOS = "clients-evaluation-videos";
const UPLOAD_EVALUATION_VIDEO = "clients-upload-evaluation-video";
const PROGRAM_VIEW_CURRENT = "clients-program-view-current";
// const PROGRAM_VIEW = "clients-program-view";
const PROGRAM_EDIT_CURRENT = "clients-program-edit-current";
const PROGRAM_CREATE_NEW = "clients-program-create-new";
const PROGRAM_VIEW_ARCHIVE = "clients-program-view-archive";
const EVALUATION_VIEW_CURRENT = "clients-evaluation-view-current";
// const VIEW_EVALUATION = "clients-view-evaluation";
const EVALUATION_EDIT_CURRENT = "clients-evaluation-edit-current";
// const EDIT_EVALUATION = "clients-edit-evaluation";
const EVALUATION_CREATE_NEW = "clients-evaluation-create-new";
const EVALUATION_VIEW_ARCHIVE = "clients-evaluation-view-archive";
const DOCUMENT_VIEW_ARCHIVE = "clients-document-view-archive";
const RE_EVALUATION_VIEW_ARCHIVE = "clients-reevaluation-view-archive";
// const VIEW_RE_EVALUATION_VIEW_ARCHIVE = "clients-view-reevaluation-view-archive";
const ONLINE_APPLICATION_FORMS_VIEW_ARCHIVE =
  "clients-online-application-forms-view-archive";
// const VIEW_ONLINE_APPLICATION_FORMS_VIEW_ARCHIVE = "clients-view-online-application-forms-view-archive";
const PAPER_APPLICATION_FORMS_VIEW_ARCHIVE =
  "clients-paper-application-forms-view-archive";
const MANAGE_CLIENTS = "manage-clients";
const MY_CLIENTS = "my-clients";
const CLIENT_SCHEDULING = "my-clients";
const UNASSIGNED_CLIENTS = "unassigned-clients";
const RE_EVALUATION_FORMS = "reevaluation-forms";
const NEW_EVALUATION_VIDEOS = "new-evaluation-videos";
const ALL_UPLOADED_VIDEOS = "all-uploaded-videos";
const APPLICATION_FORMS = "application-forms";
// const VIEW_APPLICATION_FORM = "view-application-form";
const INTERVIEW_LIST = "interview-list";
const EXPORT_CLIENTS = "export-clients";
const ACTIVITIES = "activities";
const ADD_ACTIVITY = "activities-add-activity";
// const EDIT_ACTIVITY = "activities-edit-activity";
const MANAGE_ACTIVITIES = "activities-manage-activities";
const ACTIVITY_CATEGORIES = "activities-activity-categories";
const ACTIVITY_LINK_TYPES = "activities-activity-link-types";
const COURSES = "courses";
const NACD_COURSES = "courses-nacd-courses";
const VIEW_NACD_COURSE = "courses-view-nacd-course";
const VIEW_NACD_COURSE_INTRO = "courses-view-nacd-course-intro";
const VIEW_NACD_COURSE_CATEGORY = "courses-view-nacd-course-category";
const VIEW_NACD_COURSE_ACTIVITY = "courses-view-nacd-course-activity";
const MANAGE_COURSES = "courses-manage-courses";
// const VIEW_COURSE = "courses-view-course";
// const SORT_COURSE = "courses-sort-course";
const COURSE_CATEGORIES = "courses-course-categories";
// const CREATE_COURSE_CATEGORY = "courses-create-course-category";
// const SORT_COURSE_CATEGORY = "courses-sort-course-category";
// const VIEW_COURSE_CATEGORY = "courses-view-course-category";
// const EDIT_COURSE_CATEGORY = "courses-edit-course-category";
// const CREATE_COURSE_CATEGORY_CONTENT = "courses-create-course-category-content";
// const VIEW_COURSE_CATEGORY_CONTENT = "courses-view-course-category-content";
// const EDIT_COURSE_CATEGORY_CONTENT = "courses-edit-course-category-content";
const COURSE_ACTIVITIES = "courses-course-activities";
// const CREATE_COURSE_ACTIVITY = "courses-create-course-activity";
// const SORT_COURSE_ACTIVITY = "courses-sort-course-activity";
// const EDIT_COURSE_ACTIVITY = "courses-edit-course-activity";
// const VIEW_COURSE_ACTIVITY = "courses-view-course-activity";
const COURSE_INTRO_ACTIVITIES = "courses-course-intro-activities";
// const CREATE_COURSE_INTRO_ACTIVITY = "courses-create-course-intro-activity";
// const EDIT_COURSE_INTRO_ACTIVITY = "courses-edit-course-intro-activity";
// const VIEW_COURSE_INTRO_ACTIVITY = "courses-view-course-intro-activity";
const DROPDOWNS = "dropdowns";
const ANNOUNCEMENT_TYPES = "dropdowns-announcement-types";
const ANNOUNCEMENT_MESSAGE_TYPES = "dropdowns-announcement-message-types";
const APPLICATION_FORM_BEHAVIOR_DISORDERS =
  "dropdowns-application-form-behavior-disorders";
const APPLICATION_FORM_CATEGORIES = "dropdowns-application-form-categories";
const APPLICATION_FORM_CLIENT_LIVE_WITH_OPTIONS =
  "dropdowns-application-form-client-live-with-options";
const APPLICATION_FORM_COGNITIVE_SKILLS =
  "dropdowns-application-form-cognitive-skills";
const APPLICATION_FORM_COME_FROM_OPTIONS =
  "dropdowns-application-form-come-from-options";
const APPLICATION_FORM_COMMON_ANSWERS =
  "dropdowns-application-form-common-answers";
const APPLICATION_FORM_COMPLETED_BY_OPTIONS =
  "dropdowns-application-form-completed-by-options";
const APPLICATION_FORM_DISCOUNT_NAMES =
  "dropdowns-application-form-discount-names";
const APPLICATION_FORM_DISCOUNT_TYPES =
  "dropdowns-application-form-discount-types";
const APPLICATION_FORM_EDUCATIONAL_PLACEMENTS =
  "dropdowns-application-form-educational-placements";
const APPLICATION_FORM_GUIDES = "dropdowns-application-form-guides";
const APPLICATION_FORM_HAND_PREFERENCE_ABILITIES =
  "dropdowns-application-form-hand-preference-abilities";
const APPLICATION_FORM_HAND_PREFERENCE_HANDS =
  "dropdowns-application-form-hand-preference-hands";
const APPLICATION_FORM_HEALTH_EARS = "dropdowns-application-form-health-ears";
const APPLICATION_FORM_HEALTH_EYE_DISEASES =
  "dropdowns-application-form-health-eye-diseases";
const APPLICATION_FORM_HEALTH_FOOD_ALLERGIES =
  "dropdowns-application-form-health-food-allergies";
const APPLICATION_FORM_HEALTH_PRODUCTS =
  "dropdowns-application-form-health-products";
const APPLICATION_FORM_HEALTH_SPECIALISTS =
  "dropdowns-application-form-health-specialists";
const APPLICATION_FORM_HEALTH_TIME_PERIODS =
  "dropdowns-application-form-health-time-periods";
const APPLICATION_FORM_INFO_TYPES = "dropdowns-application-form-info-types";
const APPLICATION_FORM_LANGUAGE_SKILLS =
  "dropdowns-application-form-language-skills";
const APPLICATION_FORM_MATH_SKILLS = "dropdowns-application-form-math-skills";
const APPLICATION_FORM_PHYSICAL_MOTOR_SKILL_DISORDERS =
  "dropdowns-application-form-physical-motor-skill-disorders";
const APPLICATION_FORM_STATUSES = "dropdowns-application-form-statuses";
const APPLICATION_FORM_TYPES = "dropdowns-application-form-types";
const CHAPTERS = "dropdowns-chapters";
const CLIENT_LIVE_WITH_OPTIONS = "dropdowns-client-live-with-options";
const CLIENT_STATUSES = "dropdowns-client-statuses";
const CONTACTS = "dropdowns-contacts";
const COURSE_STATUSES = "dropdowns-course-statuses";
const DAYS = "dropdowns-days";
const DEVELOPMENTAL_PROFILE_CATEGORIES =
  "dropdowns-developmental-profile-categories";
const DEVELOPMENTAL_PROFILE_DESCRIPTIONS =
  "dropdowns-developmental-profile-descriptions";
const DOCUMENT_TYPES = "dropdowns-document-types";
const EDUCATIONS = "dropdowns-educations";
const EVALUATION_TYPES = "dropdowns-evaluation-types";
const EVALUATION_VIDEO_TAGS = "dropdowns-evaluation-video-tags";
const FAMILY_STATUSES = "dropdowns-family-statuses";
const HEARD_FROM_OPTIONS = "dropdowns-heard-from-options";
const LANGUAGES = "dropdowns-languages";
const LIMBO_ARCHIVE_REASONS = "dropdowns-limbo-archive-reasons";
const MONTHS = "dropdowns-months";
const PROGRAM_CODES = "dropdowns-program-codes";
const PROGRAM_TYPES = "dropdowns-program-types";
const RE_EVALUATION_ASSISTANTS = "dropdowns-reevaluation-assistants";
const RE_EVALUATION_STATUSES = "dropdowns-reevaluation-statuses";
const RE_EVALUATION_TIME_PERIODS = "dropdowns-reevaluation-time-periods";
const REPORT_STATUSES = "dropdowns-report-statuses";
const REPORT_TYPES = "dropdowns-report-types";
const WHY_DROPPED_REASONS = "dropdowns-why-dropped-reasons";
const THEMES = "dropdowns-themes";
const SCHEDULE_TYPES = "dropdowns-schedule-types";
const UPCOMING_EVALUATION_TIME_ZONES =
  "dropdowns-upcoming-evaluation-time-zones";
const RE_EVALUATION_PROCESSING_ACTIVITY_TYPES =
  "dropdowns-re-evaluation-processing-activity-types";
const HOURS = "dropdowns-hours";
const MINUTES = "dropdowns-minutes";
const TIME_PERIODS = "dropdowns-time-periods";
const STAFF = "staff";
const LOGIN_AS_STAFF = "staff-login-as-staff";
const MANAGE_STAFF_PROFILES = "staff-manage-staff-profiles";
// const VIEW_STAFF = "staff-view-staff";
// const EDIT_STAFF = "staff-edit-staff";
const STAFF_CHANGE_PASSWORD = "staff-staff-change-password";
const CREATE_STAFF_PROFILE = "staff-create-staff-profile";
const MANAGE_STAFF_TEAM = "staff-manage-staff-team";
const SYSTEM = "system";
const DEVELOPMENTAL_PROFILE_ITEMS = "system-developmental-profile-items";
const COMMON_FORMS = "system-common-forms";
const LIMBO_STEPS = "system-limbo-steps";
// const CREATE_LIMBO_STEP = "system-create-limbo-step";
// const VIEW_LIMBO_STEP = "system-view-limbo-step";
// const EDIT_LIMBO_STEP = "system-edit-limbo-step";
const LIMBO_STEP_ARTICLES = "system-limbo-step-articles";
// const CREATE_LIMBO_STEP_ARTICLE = "system-create-limbo-step-article";
// const VIEW_LIMBO_STEP_ARTICLE = "system-view-limbo-step-article";
// const EDIT_LIMBO_STEP_ARTICLE = "system-edit-limbo-step-article";
const APPLICATION_FORM_PAGES = "system-application-form-pages";
// const CREATE_APPLICATION_FORM_PAGE = "system-create-application-form-page";
// const VIEW_APPLICATION_FORM_PAGE = "system-view-application-form-page";
// const EDIT_APPLICATION_FORM_PAGE = "system-edit-application-form-page";
const APPLICATION_FORM_PAGE_ASSIGNMENTS =
  "system-application-form-page-assignments";
const APPLICATION_FORM_PARTS = "system-application-form-parts";
// const SORT_APPLICATION_FORM_PARTS = "system-sort-application-form-parts";
const APPLICATION_FORM_COUNTRIES = "system-application-form-countries";
// const SORT_APPLICATION_FORM_COUNTRIES = "system-sort-application-form-countries";
const APPLICATION_FORM_INCOMES = "system-application-form-incomes";
const APPLICATION_FORM_FEES = "system-application-form-fees";
// const CREATE_APPLICATION_FORM_FEE = "system-create-application-form-fee";
// const VIEW_APPLICATION_FORM_FEE = "system-view-application-form-fee";
// const EDIT_APPLICATION_FORM_FEE = "system-edit-application-form-fee";
const APPLICATION_FORM_DISCOUNTS = "system-application-form-discounts";
const APPLICATION_FORM_COUNTRY_INCOME_FEES =
  "system-application-form-country-income-fees";
// const SORT_APPLICATION_FORM_COUNTRY_INCOME_FEES = "system-sort-application-form-country-income-fees";
const APPLICATION_FORM_COUNTRY_INCOME_DISCOUNTS =
  "system-application-form-country-income-discounts";
// const SORT_APPLICATION_FORM_COUNTRY_INCOME_DISCOUNTS = "system-sort-application-form-country-income-discounts";
const TIME_ZONES = "system-time-zones";
const CRON_EMAIL_LAYOUTS = "system-cron-email-layouts";
// const CREATE_CRON_EMAIL_LAYOUT = "system-create-cron-email-layout";
// const VIEW_CRON_EMAIL_LAYOUT = "system-view-cron-email-layout";
// const EDIT_CRON_EMAIL_LAYOUT = "system-edit-cron-email-layout";
const EMAIL_LAYOUTS = "system-email-layouts";
// const CREATE_EMAIL_LAYOUT = "system-create-email-layout";
// const VIEW_EMAIL_LAYOUT = "system-view-email-layout";
// const EDIT_EMAIL_LAYOUT = "system-edit-email-layout";
const EMAIL_NOTIFICATIONS = "system-email-notifications";
// const CREATE_EMAIL_NOTIFICATION = "system-create-email-notification";
// const VIEW_EMAIL_NOTIFICATION = "system-view-email-notification";
// const EDIT_EMAIL_NOTIFICATION = "system-edit-email-notification";
const EMAIL_LOGS = "system-email-logs";
// const VIEW_EMAIL_LOG = "system-view-email-log";
const AUTH_LOGS = "system-auth-logs";
const FEEDBACKS = "system-feedbacks";
const RECOMMENDED_EVALUATION_VIDEOS = "system-recommended-evaluation-videos";
// const EVALUATION_VIDEO_COMMENTS = "system-evaluation-video-comments";
const ERROR_LOGS = "system-error-logs";
const ANNOUNCEMENTS = "system-announcements";
const REPORTS = "reports";
const HISTORICAL_REPORTS = "reports-historical-reports";
const ALL_REPORTS = "reports-all-reports";
const CHAPTERS_REPORTS = "reports-chapters-reports";
const VIEW_CHAPTER_STATISTIC = "reports-view-chapter-statistic";
const STAFF_REPORTS = "reports-staff-reports";
const VIEW_STAFF_STATISTIC = "reports-view-staff-statistic";
const RBAC = "rbac";
const ROLES = "rbac-roles";
// const VIEW_ROLE = "rbac-view-role";
const PERMISSIONS = "rbac-permissions";
// const VIEW_PERMISSION = "rbac-view-permission";
const PERMISSION_CATEGORIES = "rbac-permission-categories";

export {
  DASHBOARD,
  MY_DASHBOARD,
  VIEW_MY_PROFILE,
  EDIT_MY_PROFILE,
  CHANGE_MY_PASSWORD,
  FAMILIES,
  VIEW_FAMILY_PROFILE,
  EDIT_FAMILY_PROFILE,
  CHANGE_FAMILY_PASSWORD,
  ADD_NEW_CLIENT,
  CLIENTS_LIST,
  EMAIL_ARCHIVE,
  // VIEW_EMAIL_ARCHIVE,
  PAGE_SETTINGS,
  BILLING_INFORMATION,
  COMPLETED_LIMBO_STEPS,
  LOGIN_AS_FAMILY,
  // CREATE_LIMBO_STEP,
  // VIEW_LIMBO_STEP,
  // EDIT_LIMBO_STEP,
  MANAGE_FAMILIES,
  MY_FAMILIES,
  CREATE_FAMILY,
  LIMBO_FAMILIES,
  EXPORT_FAMILIES,
  CLIENTS,
  VIEW_CLIENT_PROFILE,
  EDIT_CLIENT_PROFILE,
  EVALUATION_VIDEOS,
  UPLOAD_EVALUATION_VIDEO,
  PROGRAM_VIEW_CURRENT,
  // PROGRAM_VIEW,
  PROGRAM_EDIT_CURRENT,
  PROGRAM_CREATE_NEW,
  PROGRAM_VIEW_ARCHIVE,
  EVALUATION_VIEW_CURRENT,
  // VIEW_EVALUATION,
  EVALUATION_EDIT_CURRENT,
  // EDIT_EVALUATION,
  EVALUATION_CREATE_NEW,
  EVALUATION_VIEW_ARCHIVE,
  DOCUMENT_VIEW_ARCHIVE,
  RE_EVALUATION_VIEW_ARCHIVE,
  // VIEW_RE_EVALUATION_VIEW_ARCHIVE,
  ONLINE_APPLICATION_FORMS_VIEW_ARCHIVE,
  // VIEW_ONLINE_APPLICATION_FORMS_VIEW_ARCHIVE,
  PAPER_APPLICATION_FORMS_VIEW_ARCHIVE,
  MANAGE_CLIENTS,
  MY_CLIENTS,
  CLIENT_SCHEDULING,
  UNASSIGNED_CLIENTS,
  RE_EVALUATION_FORMS,
  NEW_EVALUATION_VIDEOS,
  ALL_UPLOADED_VIDEOS,
  APPLICATION_FORMS,
  // VIEW_APPLICATION_FORM,
  INTERVIEW_LIST,
  EXPORT_CLIENTS,
  ACTIVITIES,
  ADD_ACTIVITY,
  MANAGE_ACTIVITIES,
  // EDIT_ACTIVITY,
  ACTIVITY_CATEGORIES,
  ACTIVITY_LINK_TYPES,
  COURSES,
  NACD_COURSES,
  VIEW_NACD_COURSE,
  VIEW_NACD_COURSE_INTRO,
  VIEW_NACD_COURSE_CATEGORY,
  VIEW_NACD_COURSE_ACTIVITY,
  MANAGE_COURSES,
  // VIEW_COURSE,
  // SORT_COURSE,
  COURSE_CATEGORIES,
  // CREATE_COURSE_CATEGORY,
  // SORT_COURSE_CATEGORY,
  // VIEW_COURSE_CATEGORY,
  // EDIT_COURSE_CATEGORY,
  // CREATE_COURSE_CATEGORY_CONTENT,
  // VIEW_COURSE_CATEGORY_CONTENT,
  // EDIT_COURSE_CATEGORY_CONTENT,
  COURSE_ACTIVITIES,
  // CREATE_COURSE_ACTIVITY,
  // SORT_COURSE_ACTIVITY,
  // EDIT_COURSE_ACTIVITY,
  // VIEW_COURSE_ACTIVITY,
  COURSE_INTRO_ACTIVITIES,
  // CREATE_COURSE_INTRO_ACTIVITY,
  // EDIT_COURSE_INTRO_ACTIVITY,
  // VIEW_COURSE_INTRO_ACTIVITY,
  DROPDOWNS,
  ANNOUNCEMENT_TYPES,
  ANNOUNCEMENT_MESSAGE_TYPES,
  APPLICATION_FORM_BEHAVIOR_DISORDERS,
  APPLICATION_FORM_CATEGORIES,
  APPLICATION_FORM_CLIENT_LIVE_WITH_OPTIONS,
  APPLICATION_FORM_COGNITIVE_SKILLS,
  APPLICATION_FORM_COME_FROM_OPTIONS,
  APPLICATION_FORM_COMMON_ANSWERS,
  APPLICATION_FORM_COMPLETED_BY_OPTIONS,
  APPLICATION_FORM_DISCOUNT_NAMES,
  APPLICATION_FORM_DISCOUNT_TYPES,
  APPLICATION_FORM_EDUCATIONAL_PLACEMENTS,
  APPLICATION_FORM_GUIDES,
  APPLICATION_FORM_HAND_PREFERENCE_ABILITIES,
  APPLICATION_FORM_HAND_PREFERENCE_HANDS,
  APPLICATION_FORM_HEALTH_EARS,
  APPLICATION_FORM_HEALTH_EYE_DISEASES,
  APPLICATION_FORM_HEALTH_FOOD_ALLERGIES,
  APPLICATION_FORM_HEALTH_PRODUCTS,
  APPLICATION_FORM_HEALTH_SPECIALISTS,
  APPLICATION_FORM_HEALTH_TIME_PERIODS,
  APPLICATION_FORM_INFO_TYPES,
  APPLICATION_FORM_LANGUAGE_SKILLS,
  APPLICATION_FORM_MATH_SKILLS,
  APPLICATION_FORM_PHYSICAL_MOTOR_SKILL_DISORDERS,
  APPLICATION_FORM_STATUSES,
  APPLICATION_FORM_TYPES,
  CHAPTERS,
  CLIENT_LIVE_WITH_OPTIONS,
  CLIENT_STATUSES,
  CONTACTS,
  COURSE_STATUSES,
  DAYS,
  DEVELOPMENTAL_PROFILE_CATEGORIES,
  DEVELOPMENTAL_PROFILE_DESCRIPTIONS,
  DOCUMENT_TYPES,
  EDUCATIONS,
  EVALUATION_TYPES,
  EVALUATION_VIDEO_TAGS,
  FAMILY_STATUSES,
  HEARD_FROM_OPTIONS,
  LANGUAGES,
  LIMBO_ARCHIVE_REASONS,
  MONTHS,
  PROGRAM_CODES,
  PROGRAM_TYPES,
  RE_EVALUATION_ASSISTANTS,
  RE_EVALUATION_STATUSES,
  RE_EVALUATION_TIME_PERIODS,
  REPORT_STATUSES,
  REPORT_TYPES,
  WHY_DROPPED_REASONS,
  THEMES,
  SCHEDULE_TYPES,
  UPCOMING_EVALUATION_TIME_ZONES,
  RE_EVALUATION_PROCESSING_ACTIVITY_TYPES,
  HOURS,
  MINUTES,
  TIME_PERIODS,
  STAFF,
  MANAGE_STAFF_PROFILES,
  LOGIN_AS_STAFF,
  // VIEW_STAFF,
  // EDIT_STAFF,
  STAFF_CHANGE_PASSWORD,
  CREATE_STAFF_PROFILE,
  MANAGE_STAFF_TEAM,
  SYSTEM,
  DEVELOPMENTAL_PROFILE_ITEMS,
  COMMON_FORMS,
  LIMBO_STEPS,
  LIMBO_STEP_ARTICLES,
  // CREATE_LIMBO_STEP_ARTICLE,
  // VIEW_LIMBO_STEP_ARTICLE,
  // EDIT_LIMBO_STEP_ARTICLE,
  APPLICATION_FORM_PAGES,
  // CREATE_APPLICATION_FORM_PAGE,
  // VIEW_APPLICATION_FORM_PAGE,
  // EDIT_APPLICATION_FORM_PAGE,
  APPLICATION_FORM_PAGE_ASSIGNMENTS,
  APPLICATION_FORM_PARTS,
  // SORT_APPLICATION_FORM_PARTS,
  APPLICATION_FORM_COUNTRIES,
  // SORT_APPLICATION_FORM_COUNTRIES,
  APPLICATION_FORM_INCOMES,
  APPLICATION_FORM_FEES,
  // CREATE_APPLICATION_FORM_FEE,
  // VIEW_APPLICATION_FORM_FEE,
  // EDIT_APPLICATION_FORM_FEE,
  APPLICATION_FORM_DISCOUNTS,
  APPLICATION_FORM_COUNTRY_INCOME_FEES,
  // SORT_APPLICATION_FORM_COUNTRY_INCOME_FEES,
  APPLICATION_FORM_COUNTRY_INCOME_DISCOUNTS,
  // SORT_APPLICATION_FORM_COUNTRY_INCOME_DISCOUNTS,
  TIME_ZONES,
  CRON_EMAIL_LAYOUTS,
  // CREATE_CRON_EMAIL_LAYOUT,
  // VIEW_CRON_EMAIL_LAYOUT,
  // EDIT_CRON_EMAIL_LAYOUT,
  EMAIL_LAYOUTS,
  // CREATE_EMAIL_LAYOUT,
  // VIEW_EMAIL_LAYOUT,
  // EDIT_EMAIL_LAYOUT,
  EMAIL_NOTIFICATIONS,
  // CREATE_EMAIL_NOTIFICATION,
  // VIEW_EMAIL_NOTIFICATION,
  // EDIT_EMAIL_NOTIFICATION,
  EMAIL_LOGS,
  // VIEW_EMAIL_LOG,
  AUTH_LOGS,
  FEEDBACKS,
  RECOMMENDED_EVALUATION_VIDEOS,
  // EVALUATION_VIDEO_COMMENTS,
  ERROR_LOGS,
  ANNOUNCEMENTS,
  REPORTS,
  HISTORICAL_REPORTS,
  ALL_REPORTS,
  CHAPTERS_REPORTS,
  VIEW_CHAPTER_STATISTIC,
  STAFF_REPORTS,
  VIEW_STAFF_STATISTIC,
  RBAC,
  ROLES,
  // VIEW_ROLE,
  PERMISSIONS,
  // VIEW_PERMISSION,
  PERMISSION_CATEGORIES,
};
